<template>
  <app-code
    class="my-0"
    :copyCode="copyCode"
    v-bind="$attrs"
  >
    <prism
      ref="code"
      :code="code"      
      :inline="inline"
      :language="language"
    />
  </app-code>
</template>

<script>
  // Imports
  import 'markdown-it-prism'
  import 'prismjs/themes/prism.css'
  import 'prismjs/components/prism-bash'
  import 'prismjs/components/prism-python'
  // Components
  import Prism from 'vue-prism-component'
  import AppCode from './AppCode'
  export default {
    name: 'Markup',
    components: { Prism, AppCode },
    props: {
      code: String,
      copyCode: String,
      inline: Boolean,
      language: String,
    },
  }
</script>